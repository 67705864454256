@import "mixin";

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/*Lenyitó gomb font fix*/
.md-select-value .md-select-icon {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

md-input-container:not(.md-input-invalid).md-input-has-value {
  font-size: 16px;
  label {
    font-size: 12px;
  }
}

md-input-container {
  label {
    color: rgb(0, 0, 0);
  }
}

.disabled {
  label {
    color: rgba(0, 0, 0, 0.26);
  }
}

//
//md-content:not(.filter-lg) {
//  md-select:not([disabled]){
//    md-select-value {
//      span {
//        color: rgb(0, 0, 0);
//      }
//    }
//  }
//}
//store chip autocomplete style
.autocomplete-menu {
  .template-image {
    display: inline-block;
    width: 36px;
    max-width: 36px;
  }

  .template-content {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    margin: 10px 10px;
  }

}

.user-autocomplete {
  md-autocomplete[md-floating-label] md-input-container {
    padding-bottom: 0px;

  }

}

.store-autocomplete input {
  width: 250px;
}

.dialog-header {
  background-color: $lg-deep-purple;
}

.validation-messages {
  font-size: 12px;
  padding-right: 5px;
  padding-top: 5px;
  color: $error;
}

.md-datepicker-button {
  .md-button {
    .md-icon-button {
      margin-left: 0;
      padding-left: 0;
      width: 22px;
    }
  }
}

.md-button.toggle-button {
  margin: 0;
  min-width: 45px;
  min-height: 10px;
  height: 25px;
  line-height: normal;
  border: 1px solid $border-grey;
}

.toggle-button-group {
  margin-top: 18px;
  margin-bottom: 18px;
  margin-right: 4px;
}

.toggle-button {
  background-color: $border-light;
  margin: 0;
  font-size: 10px;

  &.toggle-button--left {
    border-radius: 3px 0 0 3px;
    border-right: none;
    &.toggle {
      border-color: $border-dark;
    }
  }

  &.toggle-button--center {
    border-radius: 0;
    border-right: none;
    &.toggle {
      border-color: $border-dark;
    }
  }

  &.toggle-button--right {
    border-radius: 0 3px 3px 0;
    &.toggle {
      border-color: black;
    }
  }

  &.sunday {
    color: $lg-red;
  }

  &.toggle {
    background-color: $lg-deep-purple;
    color: $lg-white;
  }
}

md-datepicker {
  margin-right: 0;
}

.filter-lg {
  background-color: $lg-deep-purple;
  color: white;

  md-input-container:not(.md-input-invalid).md-input-has-value label {
    color: white;
  }

  md-select .md-select-icon {
    color: white;
  }

  md-select .md-select-value {
    border-bottom-color: rgba(255, 255, 255, 0.4);
  }

  .md-input-focused *, .filter-lg .md-focused *, .filter-lg .md-datepicker-focused {
    border-bottom-color: $lg-bright-purple !important;
    color: $lg-bright-purple !important;
  }
}

md-select:focus .md-select-value {
  color: white;
}

md-checkbox {
  &.md-checked {
    .md-icon {
      background-color: $lg-deep-purple;
    }
  }
}

md-chips .md-chips.md-focused {
  box-shadow: 0 2px $lg-deep-purple !important;

}

md-chips-wrap.md-chips {
  font-size: 12px;
}

md-input-container.md-input-has-value label:not(.md-no-float) {
  -webkit-transform: translate3d(0, 6px, 0) !important;
}

md-toolbar.dark {
  background-color: #333;
  color: white;
  font-family: lg_smart;
}

md-menu-item {
  background-color: white;
}

.input-light {
  &md-input-container:not(.md-input-invalid) {
    &.md-input-focused {
      .md-input {
        border-color: $lg-other-red;
      }
      label {
        color: $lg-other-red;
      }
    }
  }
}

md-toolbar {
  .md-table-toolbar {

    z-index: 0;
  }
}

// Windows-on mindig látszott a scrollbar
md-content {
  overflow: hidden;
}

md-progress-linear {
  height: 3px;
  .md-container {
    height: 3px;
    background-color: rgba(126, 10, 41, 0.3);
  }
}

//Material téma kikapcsolása után css fixek:
//.md-select-menu-container.md-active md-select-menu > * {
//  background-color: white;
//}
//
//.md-tooltip.md-show {
//  color: white;
//  background-color: black;
//}