table {
    //TODO: szyrt-fix.css felülbírálás
    table-layout: inherit;
}

.two-lines-content {
    div:first-child {
        font-size: 13px;
    }
    div:last-child {
        font-size: 11px;
    }
}

//overvrite filter padding
md-toolbar.md-table-toolbar .md-toolbar-tools {
    padding: 15px 24px 0 24px;
}