@font-face{
  font-family: lg_smart_bold;
  src: url('/frontend/ui/fonts/lg_smart_bold.ttf');
}
@font-face{
  font-family: lg_smart_bold_italic;
  src: url('/frontend/ui/fonts/lg_smart_bold_italic.ttf');
}
@font-face{
  font-family: lg_smart_light;
  src: url('/frontend/ui/fonts/lg_smart_light.ttf');
}
@font-face{
  font-family: lg_smart;
  src: url('/frontend/ui/fonts/lg_smart_regular.ttf');
}
@font-face{
  font-family: lg_smart_italic;
  src: url('/frontend/ui/fonts/lg_smart_regular_italic.ttf');
}
@font-face{
  font-family: lg_smart_semibold;
  src: url('/frontend/ui/fonts/lg_smart_semibold.ttf');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(/frontend/ui/fonts/MaterialIcons-Regular.woff2) format('woff2'),
  url(/frontend/ui/fonts/MaterialIcons-Regular.woff) format('woff'),
  url(/frontend/ui/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeIn {
  from {width: 0;}
  to {width: 220px;}
}

/* Standard syntax */
@keyframes fadeIn {
  from {width: 0;}
  to {width: 220px;}
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeOut {
  from {width: 220px;}
  to {width: 0;}
}

/* Standard syntax */
@keyframes fadeOut {
  from {width: 220px;}
  to {width: 0;}
}