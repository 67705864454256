.toolbar-search {
  text-align: left;
  margin-left: 16px;
  input {
    outline: none;
    border: none;
  }
}

.toolbar-filter {
  margin-left: 16px;
  text-align: left;
}

md-content {
  background-color: inherit;
}

.assignments-line {
  cursor: pointer;
  outline-color: $lg-deep-red;
  &:hover {
    background-color: rgb(240, 240, 240);
  }
}

.time-sheme--red {
  font-weight: bold;
  color: $lg-red;
}

.time-sheme--green {
  font-weight: bold;
  color: $lg-deep-green;
}

.time-sheme--harmadik {
  font-weight: bold;
  color: $lg-goldstar-yellow;
}

.two-lines-content-label {
  line-height: 22px;
  label {
    color: rgba(0, 0, 0, 0.54);
  }
  div {
    font-size: 16px;
  }
}

.statement-history {
  label {
    color: rgba(0, 0, 0, 0.54);
  }
  span {
    font-size: 16px;
    line-height: 16px;
  }
  img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .statement-history-content {
    height: 135px;
    overflow-x: auto;
  }

  .statement-history-comment {
    font-size: 16px;
    padding-left: 30px;
    color: gray;
    max-width: 500px
  }
}

//navbar hack
.md-button.md-accent {
  color: $lg-deep-red;
}

md-nav-bar md-nav-ink-bar {
  background: $lg-deep-red;
}