.user-data-menu {
  color: $lg-grey;
  display: block;
  margin: 0;
  padding-top: 8px;
}

.user-data-menu-item {
  height: 40px;
  cursor: pointer;
  margin: 0;
  padding: 0 8px;

  &.active {
    color: $lg-deep-red;
  }

  &:hover {
    background-color: $lg-light-grey;
  }

  .user-data-menu-icon {
    margin: 0;
  }

  .user-data-menu-item {
    margin: 0;
  }

  .user-data-menu-text {
    padding-top: 4px;
    padding-left: 8px;
    overflow: hidden;
    font-size: 16px;
  }
}