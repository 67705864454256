.header-logo {
  height: 35px;
  padding-left: 16px;
}

.warnings {
  float: right;
  md-menu-item {
    padding-left: 10px;
  }
}

.warning-item-image {
  fill: #ff4033;
  margin-right: 15px;
  margin-left: 10px;
}

.waning-item-text {
  font-size: 22px;
  font-family: "lg_smart";
  color: $lg-dark-grey;
}

.warnings-divider {
  color: $lg-dark-grey;
}

.user-button {
  height: 35px;
  width: 35px;
  margin-left: 8px;
}

.user-dropdown {
  position: relative;
  .user-icon {
    position: relative;
    outline: none;

    .triangle {
      border-color: transparent;
      border-style: dashed dashed solid;
      position: absolute;
      border-width: 0 8.5px 8.5px;
      right: 10.5px;
      z-index: 1000;
      height: 0;
      width: 0;
    }

    .user-card-triangle {
      @extend .triangle;
      border-bottom-color: #fff;
      margin-top: 1px;
    }

    .user-card-triangle--border {
      @extend .triangle;
      border-bottom-color: #ccc;
    }
  }
  .user-card {
    position: absolute;
    right: 0px;
    top: 41px;
    min-width: 250px;
    font-family: "lg_smart";
    line-height: 2rem;
    z-index: 999;

    .user-card-content {
      background: #fff;
      border: 1px solid #ccc;
      border-color: rgba(0, 0, 0, .2);
      color: #000;
      -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
      box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
      outline: none;
      overflow: hidden;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      padding: 10px;
    }

    .user-card-name {

    }

    .user-card-email {
      font-size: 16px;
    }

    .user-card-logout {
      color: $lg-red;
      font-size: 16px;
      text-align: right;
      padding-top: 10px;
      cursor: pointer;
    }

    .user-card-profil {
      font-weight: bold;
      color: $lg-red;
      cursor: pointer;
    }
  }
}

.menu {
  padding-top: 5px;
  width: 220px;
  .menu-block {
    list-style-image: none;
    list-style-type: none;
    color: $lg-grey;
    display: block;
    margin: 0;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }

  .menu-item {
    padding: 5px 10px 5px 25px;
    cursor: pointer;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: flex-start;

    margin: 0;

    &.active {
      color: $lg-deep-red;
    }

    &:hover {
      background-color: $lg-light-grey;
    }
    .menu-icon {
      margin: 0;
    }
    .menu-item-text {
      padding-top: 2px;
      padding-left: 15px;
      line-height: 25px;
      vertical-align: top;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
    }
  }

  .menu-label {
    margin: 0;
    padding-left: 15px;
    padding-top: 10px;
    color: $lg-grey;
    font-size: .9em;
    overflow: hidden;
    display: flex;
  }
}

.login {
  height: 100%;
  background: $lg-light-grey;

  .login-header {
    background-color: $lg-grey;
    padding: 10px;
    img {
      height: 23px;
      margin-right: 10px;
    }
    h2 {
      color: white;
      margin-top: 7px;
    }
  }

  .login-box {
    //TODO: syrt-fx miatt kell
    form {
      text-align: left;
    }
    background-color: white;
    width: 300px;

    .login-box-content {
      padding: 0 20px 10px 20px;
    }
  }

  .login-backLink {
    margin-top: 10px;
    cursor: pointer;
    color: $lg-grey;
  }

  .login-selected-img {
    margin-top: 10px;
  }

  .login-selected-name {
    font-size: 16px;
    margin: 5px 0 0 0;
  }

  .login-selected-email {
    margin: 0;

  }
}

.accountlist {
  background-color: white;
  padding: 0;
  min-width: 300px;

  .empty {
    padding: 10px;
    font-size: 14px;
  }

  .md-list-item-text {
    padding: 0 10px;

    .accouuntlist-name {
      color: $lg-red;
    }
  }

}

.accountlist-buttonbar {
  .md-button {
    background-color: white;
    margin: 0;
    border: 1px solid white;
    border-radius: 0;
    &.md-raised:not([disabled]) {
      box-shadow: none;
    }
  }
}

.user-image-medium {
  width: 64px;
  height: 64px;
}
.user-image-normal {
  width: 96px;
  height: 96px;
}

.forgotPassword {
  height: 100%;
  background: $lg-light-grey;

  .forgotPassword-header {
    background-color: $lg-grey;
    padding: 10px;
    img {
      height: 23px;
      margin-right: 10px;
    }
    h2 {
      color: white;
      margin-top: 7px;
    }
  }

  .forgotPassword-box {
    //TODO: syrt-fx miatt kell
    form {
      text-align: left;
    }
    background-color: white;
    width: 300px;

    .forgotPassword-content {

      padding: 10px 20px 10px 20px;
    }
  }

  .helptext {
    //text-align: center;
    //color: $lg-grey;
    font-size: 14px;
  }
}

.activatetoken {
  height: 100%;
  background: $lg-light-grey;

  .activatetoken-header {
    background-color: $lg-grey;
    padding: 10px;
    img {
      height: 23px;
      margin-right: 10px;
    }
    h2 {
      color: white;
      margin-top: 7px;
    }
  }

  .activatetoken-box {
    //TODO: syrt-fx miatt kell
    form {
      text-align: left;
    }
    background-color: white;
    width: 300px;

    .activatetoken-content {

      padding: 10px 20px 10px 20px;
    }
  }

  .helptext {
    //text-align: center;
    //color: $lg-grey;
    font-size: 14px;
  }
}

.mkey-text {
  font-weight: bolder;
  padding-left: 5px;
  font-size: 1.1em;
  color: white;
}