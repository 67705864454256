.store-element-image {
    display: inline-block;
    max-width: 36px;
}

.store-element-content {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    margin: 10px;
    cursor: pointer;
    //TODO: ha meglesz csinálva a store adatlap, törölni kell a commentet
    &:hover {
      color: $lg-red;
    }
    .store-element-name {}
    .store-element-address {
        font-size: 11px;
    }
}