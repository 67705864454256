@import "variable";
@import "mixin";
@import "material-overwrite";
@import "assignments_add";
@import "payroll_add";
@import "assignments";
@import "store_element";
@import "md-data-table";
@import "stats";
@import "index";
@import "user-data";

/* Transition effects */
.slide {
    -webkit-animation-name: fadeIn; /* Chrome, Safari, Opera */
    -webkit-animation-duration: 0.5s; /* Chrome, Safari, Opera */
    animation-name: fadeIn;
    animation-duration: 0.5s;
    overflow: hidden;
}
.slide.ng-hide  {
    -webkit-animation-name: fadeOut; /* Chrome, Safari, Opera */
    -webkit-animation-duration: 0.5s; /* Chrome, Safari, Opera */
    animation-name: fadeOut;
    animation-duration: 0.5s;
}

.fade.ng-enter {
    -webkit-transition: opacity 1s; /* For Safari 3.1 to 6.0 */
    transition: opacity 1s;
    opacity: 1;
    display: block;
}
.fade.ng-leave-active {
    -webkit-transition: opacity 1s; /* For Safari 3.1 to 6.0 */
    transition: opacity 1s;
    opacity: 0;
}



/*ToDo: jelenleg minden form jobbra van igazítva. Ezzel kompenzálok. Ha már minden át van írva, ezt törölni kell mindenhonnan*/
.form-left {
    text-align: left;
}
/*ToDo: a materialos selectben nincs errormessege. Kézzel kell berakni, és a megfelelő távolságok miatt kell ez a fix*/
.error-message-fix {
    height: 24px;
}

.channel-form {
    margin-top: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.channel-form--info {
    font-size: 16px;
    color: $lg-grey;
    text-align: center;
    padding: 10px 50px;
}

.page-content {
    margin: 5px;
}

.configs-page {
    .md-toolbar-tools .md-button {
        font-size: 10px;
    }
    .md-toolbar-tools .md-button:hover {
        font-size: 10px;
    }
    //.configs-page-add-icon {
    //    button svg {
    //        float: right;
    //    }
    //}
}

.channel-image {
    width: 25px;
    height: 25px;
}
.country-image {
    width: 32px;
    height: 21px;
}

.euro-text {
    margin-left: 10px;
    font-size: 16px;
    color: $lg-grey;
    width: 150px;
    height: 70px;
}
.euro-country {
    margin-left: 10px;
    font-size: 16px;
    color: $lg-grey;
    width: 120px;
}

.drop-box {
    border: 2px dashed rgba(0, 0, 0, 0.117647);
    color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px 0 20px;
    text-align: center;
    margin-bottom: 24px;
}

.dragover {
    border: 2px dashed $lg-red;

}

.md-error-theme {
    .md-toast-content {
        background-color: $error;
        opacity: 0.8;
    }
}

md-input-container .md-errors-spacer {
    min-height: 0;
}

.step-circle {
    border-radius: 50%;
    background-color: $lg-deep-purple;
    color: white;
    width: 20px;
    height: 20px;
    text-align: center;
    float: left;
    margin-right: 5px;
}

md-content {
    background-color: transparent;
}

.progress-center {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
}