.days-checkbox {
  margin-bottom: 0;
  line-height: 22.5px;
  margin-top: 20px;

  &:last-of-type {
    margin-left: 10px;
  }
}

/*szyrt-fix css felüldefiniálása*/
.assignment-add-dialog form{
  line-height: normal;
  width: 600px;
}